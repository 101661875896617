export class QuestionFreeText {

    IdFulfillmentQuestionResponseFreeText: Number;

    IdQuestion: Number;

    IdFulfillment: Number;

    FreeTextCommentsString: string;

    FreeText: string;

    constructor() {
        this.IdFulfillment = 0;
        this.IdQuestion = 0;

    }
}
