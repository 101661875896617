import { FormBuilder, FormGroup, Validators, NgForm, FormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Assessment, Theme, Criterion, Question, Contract } from '../../classes/classIndex';
import { Router, ActivatedRoute } from '@angular/router';
import { AssessmentService, ModalService, QuestionService, ContractService } from '../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-new-assessment2',
    templateUrl: './new-assessment2.component.html',
    styleUrls: ['./new-assessment2.component.css']
})
export class NewAssessment2Component implements OnInit {
    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    //new fields
    loading: boolean = false;
    assessment: Assessment = new Assessment();
    translations: string[];
    idContract = 0;

    contractList: any = [];
    selectedContracts: any = [];


    themeList: any = [];

    dropdownSettings = {
        singleSelection: false,
        text: "Select Contracts",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        enableSearchFilter: true,
        classes: "myclass custom-multiselect"
    };

    constructor(private router: Router,
        private assessmentService: AssessmentService,
        private questionService: QuestionService,
        private modalService: ModalService,
        private location: Location,
        private contractService: ContractService,
        private _activatedRoute: ActivatedRoute,
        public translate: TranslateService, private _formBuilder: FormBuilder) {
        this.translate.get(["CONTRACT_SELECT_PLACEHOLDER", "MESSAGES.OPERATION_ERROR", "MESSAGES.ASSESSMENT_UPDATED", "MESSAGES.NECCESSARY_ONE_QUESTION", "MESSAGES.NECCESSARY_QUESTIONS_FROM_2_THEMES", "MESSAGES.ASSESSMENT_UPDATED_SUCCESS", "MESSAGES.ASSESSMENT_SAVE", "MESSAGES.ASSESSMENT_CREATED", "MESSAGES.VALIDATION_ERROR", "ACTIONS.DESELECT_ALL", "ACTIONS.SELECT_ALL", "MESSAGES.CONFIRMATION_SAVE", "MESSAGES.CONFIRMATION_CANCEL", "MESSAGES.NECESSARY_INFORMATION", "MESSAGES.NECCESSARY_ONE_CONTRACT", "MESSAGES.ASSESSMENT_IN_USE", "MESSAGES.INCORRECT_DATA"]).subscribe((data: any) => {
            this.dropdownSettings.text = data["CONTRACT_SELECT_PLACEHOLDER"];
            this.dropdownSettings.selectAllText = data["ACTIONS.SELECT_ALL"];
            this.dropdownSettings.unSelectAllText = data["ACTIONS.DESELECT_ALL"];
            this.translations = data;
        });
        //Get all questions grouped in themes and Criteria
        this.loading = true;
        this.contractService.getAllContracts().map((contracts: any) => {
            return contracts.map(contract => {
                return { id: contract.IdContract, itemName: contract.Name }
            })
        }).subscribe(contracts => {
            this.contractList = contracts;
            if (this.idContract > 0)
                this.selectedContracts.push(this.contractList.filter(contract => contract.id == this.idContract)[0]);

        });

        this._activatedRoute.params.subscribe(params => {
            if (params.IdAssessment) {
                this.assessmentService.getAssessmentById(params.IdAssessment).subscribe(data => {
                    this.assessment = <Assessment>data;
                    this.assessment.ContractList.forEach(contract => {
                        this.selectedContracts.push({ id: contract.IdContract, itemName: contract.Name });
                    });
                    this.questionService.getAllQuestionsInThemes(this.assessment.IdAssessment).subscribe(themes => {
                        this.themeList = themes;
                        this.loading = false;

                        this.themeList.map(t => t.Expanded = false);
                        this.filterQuestions();
                    });
                });
            }
            else {
                if (params.IdContract)
                    this.idContract = params.IdContract;

                this.questionService.getAllQuestionsInThemes().subscribe(themes => {
                    this.themeList = themes;
                    this.loading = false;
                    this.themeList.map(t => t.Expanded = false);
                    this.filterQuestions();
                });
            }

        });
    }



    filterQuestions() {
        this.themeList.forEach(theme => {
            theme.CriterionList.forEach(criterion => {
                criterion.QuestionList.forEach(question => {
                    question.Show = !question.IsContractSpecific;
                    if (question.IsContractSpecific) {
                        let aux = this.selectedContracts.filter(x => x.id == question.IdContract);
                        if (aux.length > 0) {
                            question.Show = true;
                        } else {
                            question.Selected = false;
                            if (question.HasBranch) {
                                question.QuestionBranches.forEach(branch => {
                                    branch.Selected = false;
                                });
                            }
                        }
                    }
                });
            });
        });
    }

    ngOnInit() {
        //this.firstFormGroup = this._formBuilder.group({
        //    firstCtrl: ['', Validators.required]
        //});
        //this.secondFormGroup = this._formBuilder.group({
        //    secondCtrl: ['', Validators.required]
        //});
    }


    goBack() {
        //this.modalService.openConfirm("Are you sure?", "All changes will be lost!", () => { this.router.navigate(['/assessments']); });
        this.modalService.openConfirm(this.translations["MESSAGES.CONFIRMATION_SAVE"], this.translations["MESSAGES.CONFIRMATION_CANCEL"], () => { this.location.back(); });
    }

    validateAltWeighting(event) {
        if (event.target.value.length > 0) {
            if (event.target.value > 20) {
                event.target.value = 20;
            }
            if (event.target.value < 1) {
                event.target.value = 1;
            }
        }
    }

    saveAssessmentContracts() {
        this.assessment.ContractList = [];
        this.selectedContracts.forEach((item) => {
            let contract = new Contract();
            contract.IdContract = item.id;
            contract.Name = item.itemName;
            this.assessment.ContractList.push(contract);
        })
    }

    saveAssessment(isDraft: boolean) {
        this.saveAssessmentContracts();
        if (!this.assessment.Name || this.assessment.ContractList.length == 0) {
            this.modalService.openError(this.translations["MESSAGES.VALIDATION_ERROR"], this.translations["MESSAGES.NECESSARY_INFORMATION"]);
        }
        else {
            let questionsWeightings = [];
            let altWeightings = [];
            //Get all selected questions
            let selectedThemeCont: number = 0;
            for (let pTheme of this.themeList) {
                let isQuestionSelected: boolean = false;
                for (let pCriterion of pTheme.CriterionList) {
                    let selectedQuestions = pCriterion.QuestionList.filter(q => q.Selected);
                    for (let pQuestion of selectedQuestions) {
                        questionsWeightings.push(pQuestion);
                        isQuestionSelected = true;
                        if (pQuestion.HasBranch) {
                            for (let pQuestBranch of pQuestion.QuestionBranches) {
                                if (pQuestBranch.Selected) {
                                    questionsWeightings.push(pQuestBranch);
                                }
                            } //End question branches
                        }
                    } //End questions
                } //End criterias
                if (isQuestionSelected) {
                    selectedThemeCont++;
                }
            } //End themes



            //Must select any question
            if (questionsWeightings.length > 0) {

                //Must select questions from 2 different themes at least
                if (selectedThemeCont >= 2) {
                    //Save assessment
                    this.saveAssessmentContracts();
                    this.assessment.IsDraft = isDraft;
                    this.assessment.QuestionList = questionsWeightings;
                    this.assessmentService.addAssessment(this.assessment).subscribe((result: any) => {
                        if (result.success) {

                            let title = this.translations["MESSAGES.ASSESSMENT_CREATED"];
                            let message = this.translations["MESSAGES.ASSESSMENT_SAVE"];

                            if (this.assessment.CustomerCount != 0) {
                                //SHOW APROPIATE TITLE AND MESSAGE FOR CREATING OR UPDATING
                                title = this.translations["MESSAGES.ASSESSMENT_UPDATED"];
                                message = this.translations["MESSAGES.ASSESSMENT_UPDATED_SUCCESS"];
                            }
                            this.router.navigate(['/assessments']);
                            this.modalService.openInfo(title, message);
                        }
                        else
                            this.modalService.openError(this.translations["MESSAGES.OPERATION_ERROR"], result.message);
                    }, (err) => {
                        //INTERNAL SERVER ERROR
                        this.modalService.openError(this.translations["MESSAGES.OPERATION_ERROR"], err.message);
                    });
                } else {
                    this.modalService.openError(this.translations["MESSAGES.VALIDATION_ERROR"], this.translations["MESSAGES.NECCESSARY_QUESTIONS_FROM_2_THEMES"]);
                }
            } else {
                this.modalService.openError(this.translations["MESSAGES.VALIDATION_ERROR"], this.translations["MESSAGES.NECCESSARY_ONE_QUESTION"]);
            }
        }

    }

    deselectTheme(theme: Theme, select: boolean) {
        for (let pCrit of theme.CriterionList) {
            this.deselectCriterion(pCrit, select);
        }

    }

    deselectCriterion(criterion: Criterion, select: boolean) {
        for (let pQuestion of criterion.QuestionList) {
            if (pQuestion.Show) {
                pQuestion.Selected = select;
                if (pQuestion.QuestionBranches != null && pQuestion.QuestionBranches != undefined) {
                    for (let pQuestBranch of pQuestion.QuestionBranches) {
                        pQuestBranch.Selected = select;
                    }
                }
            }
        }
    }

    deselectQuestion(question: Question) {
        if (question.HasBranch && question.QuestionBranches != null) {
            for (let pQuestBranch of question.QuestionBranches) {
                pQuestBranch.Selected = question.Selected;
            }
        }
    }

}
